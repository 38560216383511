<template>
  <v-row class="fill-height">
    <v-col cols="12" class="mt-5">
      출금요청은 20,000만원 이상부터 가능합니다
    <br/>
      출금일은 사용자 이용내역을 확인한 후 매주 금요일 14시 이후에 입금됩니다
      <br/>
      송금수수료 500원이 차감되어 입금됩니다 
    </v-col>

    <v-col cols="12" class="mt-2" v-if="deposit_type == false">
      <validation-observer ref="observer" class="mt-8">
        <!-- v-slot="{invalid}" -->

        <form
          class="form"
          style="width: 100%"
          action=""
          @submit.prevent="submit"
        >
          <div>
            <label class="user_info_label">
              예금주
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="성함"
              rules="required"
            >
              <v-text-field
                v-model="bank_info.user_name"
                outlined
                :error-messages="errors"
                required
                dense
              ></v-text-field>
            </validation-provider>
          </div>

          <div class="mt-4">
            <label class="user_info_label">
              은행
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="은행"
              rules="required"
            >
              <v-select
                v-model="bank_info.bank_agency"
                :items="bank_list"
                item-text="name"
                item-value="name"
                :error-messages="errors"
                dense
                required
                outlined
              ></v-select>
            </validation-provider>
          </div>

          <div class="mt-4">
            <label class="user_info_label">
              계좌번호
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="계좌 번호"
              rules="required"
            >
              <v-text-field
                v-model="bank_info.bank_account_no"
                outlined
                :error-messages="errors"
                required
                dense
                type="number"
                placeholder="  -  제외"
              ></v-text-field>
            </validation-provider>
          </div>

          <div class="mt-4">
            <label class="user_info_label">
              출금액
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="출금액"
              rules="required|min:20000"
            >
              <v-text-field
                v-model="bank_info.amount"
                outlined
                :error-messages="errors"
                required
                dense
                type="number"
                placeholder="숫자만 입력"
              ></v-text-field>
            </validation-provider>
          </div>

          <div class="mt-4">
            <label class="user_info_label">
              문자받을 휴대폰번호
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="출금액"
              rules="required"
            >
              <v-text-field
                v-model="bank_info.phone"
                outlined
                :error-messages="errors"
                required
                dense
                type="number"
                placeholder="숫자만 입력"
              ></v-text-field>
            </validation-provider>
          </div>

          <div class="mt-4">
            <v-btn color="#5EB4F9"
                    class="mt-6"
                    depressed
                    block
                    style="color:#ffffff"
                    :disabled="!isComplete"
                    @click="createWithDraw"
            >
                출금 요청
            </v-btn>
          </div>
        </form>
      </validation-observer>
    </v-col>

    <v-col cols="12" class="mt-2 text-center text-h5" v-else>
      <h5>
        현재 출금 신청 중 입니다.
        <br/>
        매주 금요일 입금처리됩니다.
      </h5>
    </v-col>
  </v-row>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import axios from "../../../service/axios";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} 입력해주세요",
});

extend("min", {
  validate(value, { min }) {
    if (value >= min) {
      return true;
    }
    return "{_field_}는 {min} 원 이상이어야 합니다.";
  },
  params: ["min"],
});

export default {
  name: "Withdraw",
  components: {
    ValidationObserver,
    ValidationProvider,
  },

  computed: {
    isComplete() {
        if(this.bank_info.user_name&&this.bank_info.bank_agency&&this.bank_info.bank_account_no&&this.bank_info.phone){
            if(this.bank_info.amount >= 20000) return true 
            else return false
        } else {
            return false
        }
      
    },
  },

  data() {
    return {
      deposit_type: false,
      bank_info: {
        user_name: null,
        bank_agency: null,
        bank_account_no: null,
        amount: null,
        phone: "",
      },
      bank_list: [
        { name: "경남은행", code: "039", id: 1 },
        { name: "국민은행", code: "004", id: 2 },
        { name: "광주은행", code: "034", id: 3 },
        { name: "기업은행", code: "003", id: 4 },
        { name: "농협중앙회", code: "011", id: 5 },
        { name: "단위농협(축협)", code: "012", id: 6 },
        { name: "대구은행", code: "031", id: 7 },
        { name: "대화은행", code: "065", id: 8 },
        { name: "부산은행", code: "032", id: 9 },
        { name: "산업은행", code: "002", id: 10 },
        { name: "상호저축은행", code: "050", id: 11 },
        { name: "새마을금고", code: "045", id: 12 },
        { name: "수출입은행", code: "008", id: 13 },
        { name: "수협", code: "007", id: 14 },
        { name: "신한은행", code: "088", id: 15 },
        { name: "신협", code: "048", id: 16 },
        { name: "씨티은행", code: "027", id: 17 },
        { name: "우리은행", code: "020", id: 18 },
        { name: "우체국", code: "071", id: 19 },
        { name: "외환은행", code: "005", id: 20 },
        { name: "전북은행", code: "037", id: 21 },
        { name: "제주은행", code: "035", id: 22 },
        { name: "카카오뱅크", code: "090", id: 23 },
        { name: "케이뱅크", code: "089", id: 24 },
        { name: "한국은행", code: "001", id: 25 },
        { name: "하나은행", code: "081", id: 26 },
        { name: "SC제일은행", code: "023", id: 27 },
      ],
    };
  },

  created() {
    let accessKey = this.$cookies.get("token");
    if (accessKey != null) {
      this.depositType();
    }
  },
  methods: {
    async depositType() {
      const params = {
        accessKey: this.$cookies.get("token"),
      };
      let already = await axios.get("/withdraw/isWithdraw", { params: params })
      if (already.data.data.item === true) {
        this.deposit_type = true;
      } else {
        this.deposit_type = false;
        let getUserInfo = await axios.post("/users/info", params)
        
        const user = getUserInfo.data.data.item
        if(getUserInfo){
          this.bank_info.user_name = user.bank_holder
          this.bank_info.bank_agency = user.bank_name
          this.bank_info.bank_account_no = user.bank_account
          this.bank_info.phone = user.user_phone
        }
        // console.log('Get user Info : ', user)
      }
    },

    async createWithDraw() {
      console.log("Clicked......");
      const params = {
        accessKey: this.$cookies.get("token"),
        options: this.bank_info,
      };

      let askMoney = await axios.post("/withdraw/askWithdraw", params);
      if (askMoney) {
        console.log("Ask Money : ", askMoney.data.data.item);
        const result = askMoney.data.data.item;
        // 이미 신청이 있는 경우
        if (result === 200) {
          alert('출금신청이 완료되었습니다.')
          this.$router.replace("/home/home");
        } else {
          // this.loading_bar = false
          // this.snackbar = true;
          // this.snackbar_msg = "선물을 보냈습니다.";
        }
      } else {
        alert("No");
      }
      // this.$router.replace("/home/home");
    },
  },
};
</script>

<style lang="scss">
.user_info_label {
  font-size: 0.8rem !important;
  color: #969696 !important;
  letter-spacing: -0.07em;
}
input[type="text"] {
  border-radius: 5px;
  font-size: 16px;
  line-height: 20px;
  padding: 5px;
  width: 100%;
}
</style>
